<template lang="pug">
iframe(:src="iframeSrc" style="border:0;width:100%;height:calc(100vh - 5.625rem)" @load="$store.state.showAdminLoader = false")
</template>

<script>
  import GENERATE_OLD_TOKEN from '@/graphql/GenerateOldToken.gql';
  import { getAccountIdFromCookie } from '@/util';

  export default {
    name: 'OldEditor',
    data: () => ({
      iframeSrc: '',
    }),
    mounted() {
      this.$store.state.showAdminLoader = true;
      function bindEvent(element, eventName, eventHandler) {
        if (element.addEventListener) {
          element.addEventListener(eventName, eventHandler, false);
        } else if (element.attachEvent) {
          element.attachEvent(`on${eventName}`, eventHandler);
        }
      }
      this.$apollo
        .query({
          query: GENERATE_OLD_TOKEN,
          variables: {
            variantId: this.$route.params.id,
          },
        })
        .then(({ data }) => {
          this.$store.state.showAdminLoader = true;
          this.iframeSrc = data.url;
        });

      bindEvent(window, 'message', (e) => {
        if (e.data === 'redirectToList')
          this.$router.push({
            name: 'campaign_variants',
            params: { userId: getAccountIdFromCookie(), id: this.$route.params.caId },
          });
      });
      document.querySelector('.brand-page-wrap').classList.add('embedded');
    },
    beforeDestroy() {
      document.querySelector('.brand-page-wrap').classList.remove('embedded');
    },
  };
</script>
